import React from "react";
export function FriendModal(props) {
    return (
        <>
            <table style={{backgroundColor:"white",borderRadius:"20px",marginTop:"20px",width:"100%"}}>
                <tr style={{padding:"20px"}}>
                    <td style={{padding:"20px"}}> 
                        <img style={{width:"50px"}} src={props.img} alt="Error" />
                    </td>
                    <td style={{padding:"20px"}}>
                        <h6>{props.name}</h6>
                        <h6>{props.position}</h6>
                    </td>
                    <td style={{padding:"20px"}}>
                        <button>
                            <b>Dismiss</b>    
                        </button>
                    </td>
                    <td style={{padding:"20px"}}>
                        <button style={{color:"#0070ff",borderRadius:"5px",border:"1px solid #0070ff"}}>
                        &nbsp; <b>Connect</b> &nbsp;   
                        </button>
                    </td>
                </tr>
            </table>
        </>
    )
}

