import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Left_bar from "./Left";
import Mid_bar from "./Mid";
import Right_bar from "./Right";
import styled from "styled-components";
import FriendCard from "./FriendCard";
function FriendPage()
{
    return(
        <Container>
            <center>
                <Row style={{width:"100%"}}>
                    <Col xs="12" lg="3">
                            <Left_bar/>
                        
                    </Col>
                    <Col xs="12" lg="6" className="p-0">

                        <FriendCard/>

                    </Col>
                    <Col xs="12" lg="3">
                        <Right_bar/>
                    </Col>
                    
                </Row>
            </center>
        </Container>
    )
}

export default FriendPage;

const L= styled.div`
    padding:10px;
`;