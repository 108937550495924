import React from "react";
import datascience from "../Image/datascience.jpg";
import user1 from "../Image/user.png";
import { UserAuth } from '../Firebase/authcontext';
function Left_bar()
{
    const { logOut, user } = UserAuth();
    return(
        <>
            <div style={{background:"white",width:"auto",maxWidth:"400px",borderTopLeftRadius:"30px",borderTopRightRadius:"30px",borderBottomLeftRadius:"30px",borderBottomRightRadius:"30px"}}>
                <center>
                    <img style={{contain:"content",borderTopLeftRadius:"30px",borderTopRightRadius:"30px"}} src={datascience} alt="err"/>
                    <div style={{top:"10px",position:"relative",top:"-60px"}}>
                        <img style={{maxWidth:"80px",borderRadius:"80px"}} src={user?.photoURL} alt="err" />
                        <div style={{borderBottom:"1px solid #D5D4D4",paddingTop:"10px"}}>
                            <h5 style={{fontWeight:"600"}}>{user?.displayName}</h5>
                            <h6 style={{paddingBottom:"10px"}}>Web Devlopeer</h6>
                        </div>
                    </div>
                    {/* <div style={{position:"relative",top:"-45px",display:"flex"}}>
                        <h2 style={{contain:"content",alignItems:"left",fontWeight:"bolder",color:"#3182ce"}}> &nbsp;Recent</h2>
                    </div> */}
                    <div style={{paddingBottom:"10px",fontSize:"12px",color:"#718096",position:"relative",top:"-50px",borderBottom:"1px solid #D5D4D4",display:"flex",fontWeight:"bold"}}>

                        <table style={{width:"100%"}}>
                            <tr>
                                 <th>
                                    &nbsp;&nbsp; Who viewed your Profile
                                 </th>
                                 <th style={{color:"#3182ce"}}>
                                     400
                                 </th>
                            </tr>
                            <tr>
                                 <th>
                                    &nbsp;&nbsp; Viewes on your Post
                                 </th>
                                 <th style={{color:"#3182ce"}}>
                                     2000
                                 </th>
                            </tr>
                        </table>
                    </div>
                    <div style={{marginTop:"-40px",color:"#718096",fontSize:"14px",display:"flex"}}>
                    `   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M2 2v13.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2z"></path></svg>
                        &nbsp;&nbsp;<span style={{top:"-4px",position:"relative"}}>My Items</span>
                    </div>
                </center>
            </div>
        </>
    );
}

export default Left_bar;