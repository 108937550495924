import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import goog from "../Image/goog.png"
import { UserAuth } from '../Firebase/authcontext';
import { onAuthStateChanged } from 'firebase/auth';
import styled from 'styled-components';
import GoogleButton from 'react-google-button'
import "../CSS/signing.css"

const Signin = () => {
    const {googleSignIn ,user}= UserAuth();
    const navigate=useNavigate();
    const handleSignin= async ()=>
    {
        try
        {
            await googleSignIn();
        }
        catch(error)
        {
            console.log(error);
        }
    }
    useEffect(()=>
    {
        if(user!=null)
        {
            navigate("/home")
        }
    },[user])

  return (
    <div>
    <GoogleButton onClick={handleSignin}>
    </GoogleButton>
    </div>
  );
};

export default Signin;

const Btn_sign=styled.div`
&:hover
{
    button
    {
        background-color:#979797;
        border:(5px solid black);
    }
    @media(max-width:700px)
    {
        button
        {
            padding:0px;
        }
    }
}
`; 