
import {
  Container,
} from "@mui/material";
import { FriendModal } from "./FriendsContents";
import friendData from "../Assests/friendData";
const FriendCard = () => {
  return (
    <Container>
      {
      friendData.map((i)=>{
        return (
          <div>
            <FriendModal name={i.name} img={i.image} position={i.position} />
          </div>
        )
      })
    }
    </Container>
  );
};

export default FriendCard;