import React from "react";

function Comment(props) {
    return (
        <>
            <div style={{ backgroundColor: "#efefef", borderRadius: "10px", display: "flex", alignItems: "center" }} class="media mt-3 p-2">
                <img style={{width:"10%"}} src={props.pfp} class="mr-3 mt-1 rounded-circle" alt="..." />
                <div style={{ display: "inline-flex" }} class="media-body">
                    <p style={{textAlign:"left"}} class="mt-0"><b>{props.name}</b>  {props.comment}</p>
                </div>
            </div>
        </>
    )
}

export default Comment;