import React from "react";
import styled from "styled-components";
import logo from "../Image/logo.jpg";
import h1 from "../Image/home2.png";
import network from "../Image/networks.png";
import message from "../Image/message.png";
import bell from "../Image/icons8-bell-24.png";

import { UserAuth } from '../Firebase/authcontext';

function Navbar() {
  const { logOut, user } = UserAuth();

  const handleSignOut = async () => {
    try {
      await logOut();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Nav>
      <span style={{ display: "inline-flex" }}>
        <Logo>
          <h5 className="pt-3 pl-3">SocioConnect</h5>
        </Logo>
        {/* <span style={{ paddingLeft: "20px" }}>
          <input style={{ background: "transparent", paddingLeft: "20px", top: "14px", position: "relative", backgroundColor: "rgb(224, 220, 220)", height: "30px", border: "black", borderRadius: "20px" }} type="text" placeholder={"Search"} />
        </span> */}
        <Nav1>
          <NavListWrap>
          <NavList>
              {/* <a href="./home">
                <img src={bell} alt="err" />
                <span style={{ fontWeight: "bolder" }}>Notification</span>
              </a> */}
              &nbsp;
              &nbsp;
 
            </NavList> 
            <NavList>
              <a href="./home">
                <img href="./home" src={h1} alt="err" />
                <span style={{ fontWeight: "bolder" }}>Home</span>
              </a>
            </NavList>
            <NavList>
              <a href="./friends">
                <img src={network} alt="err" />
                <span style={{ fontWeight: "bolder" }}>User</span>
              </a>
            </NavList>
            {/* {/* <NavList>
              <a href="./home">
                <img src={message} alt="err" />
                <span style={{ fontWeight: "bolder" }}>Message</span>
              </a>
            </NavList> */}


            <User>
              <a>
              <button onClick={handleSignOut} style={{padding:"2px", display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                <img style={{ borderRadius: "80px" }} src={user?.photoURL} alt="error" />
                <span style={{ fontWeight: "bolder" }}>LogOut</span>
              </button>

              </a>
            </User>
          </NavListWrap>
        </Nav1>
      </span>
    </Nav>
  );
}

export default Navbar;

const Nav = styled.div`
  background-color: #579BB1;
  width: 100%;
  height: 54px;
`;

const Logo = styled.div`
  @media (max-width: 768px) {
    padding-left: 10px;
  }
`;
const Nav1 = styled.div`
  margin-left: 600px;
  z-index: 99;
  // color:grey;
  display: block;
  position: relative;
  right: 0;
  margin-top: 5px;
  @media(min-width:1000px){
    margin-left: 900px;
  }
  @media (max-width: 568px) {
    margin-left: auto;
    position: fixed;
    left: 0;
    bottom: 0;
    background: white;
    width: 100%;
    align-item: center;
  }
`;

const NavListWrap = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  list-style-type: none;
  width: 100%;
  .active {
    span:after {
      content: "";
      transform: scaleX(1);
      border-bottom: 2px solid var(--white, #fff);
      bottom: 0;
      left: 0;
      position: absolute;
      transition: transform 0.2s ease-in-out;
      width: 100%;
      border-color: rgba(0, 0, 0, 0.9);
    }
  }
`;

const NavList = styled.li`
  display: flex;
  align-items: center;
  a {
    align-items: center;
    background: transparent;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 400;
    justify-content: center;
    line-height: 1.5;
    min-height: 42px;
    min-width: 68px;
    position: relative;
    text-decoration: none;
    img {
      height: 25px;
    }
    span {
      color: rgba(0, 0, 0, 0.6);
      display: flex;
      align-items: center;
    }
    @media (max-width: 768px) {
      align-items: center;
      display: flex;
      width: fit-content;
      block-size: fit-content;
    }
  }
`;

const User = styled(NavList)``;
