import {React,useState} from "react";
import "../CSS/bootstrap-5.3.3-dist/bootstrap-5.3.3-dist/css/bootstrap.css";
import like from "../Image/like.png";
import comment from "../Image/comment.png";
import share from "../Image/share.png";
import Comment_Model from "./Comment_Model";
import like_white from "../Image/liked_white.png";

function MyPosts(props) {
    const [cmt_button_stat,set_cmt_state]=useState("close");
    const [liked, setLiked] = useState(false);

    const handleLike = () => {
      setLiked(!liked);
    };
    const cmt_handleclick=(e)=>{
        e.preventDefault();
        set_cmt_state(cmt_button_stat==="open"?"close":"open");
    }
    // async function Like(){
    //     try{

    //         let key=props.Key;
    //         let post_message=props.Description;
    //         let user_name=props.User_name;
    //         let likes=props.Like+1;
    //         let followers=props.Followers;
    //         let url=props.Img_Url;
    //         let pfp=props.Pfp;

    //         const option = {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json"
    //             },
    //             body: JSON.stringify({
    //                 post_message,
    //                 user_name,
    //                 likes,
    //                 followers,
    //                 img_url: url, 
    //                 pfp:pfp
    //             })
                
    //         }
    // }
    // catch(err){
    //     console.log(err);
    // }
    // }

    return (
        
        <>
            <Comment_Model id={props.Key} cmt_stat={cmt_button_stat} onClick={cmt_handleclick}></Comment_Model>
            <div style={{ paddingTop: "20px"}}>
                <div style={{ backgroundColor: "white", borderRadius: "20px" }}>
                    <div>
                        <table style={{ display: "flex", padding: "10px" }}>
                            <tr>
                                <td>
                                    {/* <span style={{ color: "black", fontWeight: "500", fontSize: "20px", borderRadius: "50%", backgroundColor: "rgb(34 198 0)", padding: "10px" }}>
                                        MT
                                    </span> */}
                                    <img style={{borderRadius: "50%",width:"50px"}} src={props.Pfp} alt="" />
                                </td>
                                <td>
                                    <div>
                                        <div>
                                            &nbsp;&nbsp;{props.User_name}
                                        </div>
                                        <div style={{ color: "rgb(167, 167, 167)", fontSize: "12px" }}>
                                            &nbsp;&nbsp;{props.Followers} followers
                                        </div>
                                        <div style={{ color: "rgb(167, 167, 167)", fontSize: "12px", display: "flex" }}>
                                            &nbsp;&nbsp;2w  &nbsp;<svg style={{ position: "relative", top: "3px", color: "black" }} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" className="main_earthIcon__1u5lK" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><g><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm6.355-6.048v-.105c0-.922 0-1.343-.652-1.716a7.374 7.374 0 0 0-.645-.325c-.367-.167-.61-.276-.938-.756a12.014 12.014 0 0 1-.116-.172c-.345-.525-.594-.903-1.542-.753-1.865.296-2.003.624-2.085 1.178l-.013.091c-.121.81-.143 1.082.195 1.437 1.265 1.327 2.023 2.284 2.253 2.844.112.273.4 1.1.202 1.918a8.185 8.185 0 0 0 3.151-2.237c.11-.374.19-.84.19-1.404zM12 3.833c-2.317 0-4.41.966-5.896 2.516.177.123.331.296.437.534.204.457.204.928.204 1.345 0 .328 0 .64.105.865.144.308.766.44 1.315.554.197.042.399.084.583.135.506.14.898.595 1.211.96.13.151.323.374.42.43.05-.036.211-.211.29-.498.062-.22.044-.414-.045-.52-.56-.66-.529-1.93-.356-2.399.272-.739 1.122-.684 1.744-.644.232.015.45.03.614.009.622-.078.814-1.025.949-1.21.292-.4 1.186-1.003 1.74-1.375A8.138 8.138 0 0 0 12 3.833z"></path></g></svg>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                        <div style={{ display: "flex" }}>
                            <div style={{ textAlign: "left", padding: "15px" }}>
                                {props.Description}
                            </div>
                        </div>
                        <div style={{ display: "flex", padding: "15px", color: "rgb(10, 116, 221)" }}>
                            <a href="" style={{ textDecoration: "none", fontWeight: "bold" }}>See translation</a>
                        </div>
                    </div>
                    
                    {props.Img_Url && <img loading="lazy" src={props.Img_Url} alt="Error" />}
                    <div>
                        
                    </div>
                    <div style={{ textAlign: "left" }}> &nbsp;&nbsp;
                        <span style={{ width: "33%", textAlign: "center" }}>4 Comments</span>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <button onClick={handleLike} className="btn" style={{ color: liked ? "#ffffff" : "#3365F0", backgroundColor: liked ? "#3365F0" : "rgb(234, 234, 234)", border: "1px solid #c6bcbc", width: "33%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <img src={liked?like_white:like} alt="err" style={{width:"15%", marginRight: "5px" }} />{liked ? "Liked" : "Like"}
                    </button>
                        <button onClick={cmt_handleclick} className="btn" style={{ color: "#3365F0", width: "33%", backgroundColor: "rgb(234, 234, 234)", border: "1px solid #c6bcbc", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <img src={comment} alt="err" style={{ width:"15%",marginRight: "5px" }} />Comment
                        </button>
                        <button className="btn" style={{ color: "#3365F0", width: "33%", backgroundColor: "rgb(234, 234, 234)", border: "1px solid #c6bcbc", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <img src={share} alt="err" style={{width:"15%", marginRight: "5px" }} />Share
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MyPosts;
