
import img from "../Image/user.png"
const friendData=[
    {
        name:"Zakie Khan",
        position:"Reacct JS Developer",
        image:img
    },
    {
        name:"Tanhsiq Mehrunkar",
        position:"Web Devloper",
        image:img
    },
    {
        name:"Soham Kothari",
        position:"Python Developer",
        image:img
    },
    {
        name:"Kartikey Yadav",
        position:"Java Developer",
        image:img
    },
    {
        name:"Ben 10",
        position:"Reeler on Instagram",
        image:img
    }
]
export default friendData;