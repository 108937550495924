import React, { useEffect } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import gallary from "../Image/gallery.png"
import video from "../Image/vid.png"
import styled from "styled-components";
import { UserAuth } from "../Firebase/authcontext";
import Post_Model from "./Post_model";
import { useState } from "react";
import MyPosts from "./Posts";
import { Spinner } from "react-bootstrap";
// import "../CSS/bootstrap-5.3.3-dist/bootstrap-5.3.3-dist/css/bootstrap.css";


function Mid_bar() {
    const { logOut, user } = UserAuth();
    const [button_stat, set_state] = useState("close");
    const [loading,setloading]=useState(true);
    const [Post_Data,Set_Post_Data]=useState({});
    const handleClick = (e) => {
        e.preventDefault();
        set_state(button_stat === "open" ? "close" : "open");
    }



    useEffect(()=>{
        const GetData=async ()=>{
            setloading(true);
            let data=await fetch("https://lindin-clone-30cfa-default-rtdb.asia-southeast1.firebasedatabase.app/UserData.json");
            data=await data.json();
            return data;
        }
        let json_data=GetData();
        json_data.then((i)=>{
            if(i==null){
                Set_Post_Data({});
            }
            Set_Post_Data(i);
            setloading(false);
        })

    },[button_stat])

    return (
        <div>
            
            <div style={{ backgroundColor: "white", borderRadius: "20px" }}>
                <div style={{ display: "flex", alignItems: "center", padding: "10px", paddingTop: "15px" }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <img  style={{ width: "40px", borderRadius: "80px" }} src={user?.photoURL} alt="error"/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <div className="d-grid gap-2 col-10 mx-auto">
                        <button className="btn " onClick={handleClick} style={{ display: "flex", alignItems: "center", maxWidth: "90%", height: "40px", borderRadius: "20px", border: "1px solid rgba(200, 200, 200, 0.8)", padding: "0 15px" }} type="text" >&nbsp;&nbsp;Start Your Post &nbsp;&nbsp;</button>
                    </div>
                    </div>
                <div style={{ padding: "20px" }}>
                    <Row>
                        <Col>
                            <Btn>
                                <button style={{ fontWeight: "bolder", display: "flex", alignItems: "center", color: "#718096", padding: "10px" }}>
                                    <img style={{ width: "35px", marginRight: "10px" }} src={gallary} alt="" />
                                    Photo
                                </button>
                            </Btn>
                        </Col>
                        <Col>
                            <Btn>
                                <button style={{ fontWeight: "bolder", display: "flex", alignItems: "center", color: "#718096", padding: "10px" }}>
                                    <img style={{ width: "35px", marginRight: "10px" }} src={video} alt="" />
                                    Video
                                </button>
                            </Btn>
                        </Col>
                    </Row>
                </div>
            </div>
            {
                loading? 
                <div style={{height:"70vh",display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Spinner variant="primary"/>
                </div>
                :
                Object.keys(Post_Data).reverse().map(key=>
                {
                    return (<MyPosts Key={key} Like={Post_Data[key].Like} User_name={Post_Data[key].user_name} Followers={Post_Data[key].followers} Description={Post_Data[key].post_message} Img_Url={Post_Data[key].img_url} Pfp={Post_Data[key].pfp}/>)
                })
            }
            <Post_Model button_stat={button_stat} handleClick={handleClick}/>
            
        </div>
    )
}

export default Mid_bar;

const Btn = styled.div`
    &:hover {
        border-radius: 10px;
        background-color: #e5e5e5;
    }
    &:focus {
        outline: 0;
    }
`;
