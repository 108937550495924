import './App.css';
import Navbar from './zak_component/Nav';
import Home1 from './zak_component/Home1';
import Sign from './zak_component/Sign_in';
import { AuthContextProvider } from './Firebase/authcontext';
import {BrowserRouter,Router, Routes, Route} from 'react-router-dom';
import Protected from './Firebase/protected';
import 'bootstrap/dist/css/bootstrap.min.css';
import FriendPage from './zak_component/Friends';

function App() {
  return (
    <div className='App'>
      <AuthContextProvider>
          <BrowserRouter>
            <Routes>
              <Route path='/friends' element={
                <Protected>
                  <Navbar/>
                  <div style={{height:"100vh",backgroundColor:"rgb(234, 234, 234)"}}>
                    <FriendPage/>
                  </div>
                </Protected>
              }></Route>
            <Route path="/home" element={
              <Protected>
                  <Navbar/>
                  <Home1/>
              </Protected>
            }></Route>
            <Route path='/' element={
              <div>
                <Sign/>
              </div>
            }></Route>

            </Routes>
        </BrowserRouter>
      </AuthContextProvider>
    </div>

  );
}

export default App;
