import React, { useState } from "react";
import styled from "styled-components";
function Right_bar()
{
    const [reqested,setrequested]=useState(false);
    const hadleRequest=()=>{
        setrequested(!reqested);
    }
    return(
        <Con>
            <div style={{padding:"10px",textAlign:"left",background:"white",width:"auto",maxWidth:"400px",borderTopLeftRadius:"30px",borderTopRightRadius:"30px",borderBottomLeftRadius:"30px",borderBottomRightRadius:"30px"}}>
                <p style={{fontWeight:"500"}}>
                    &nbsp; Add New Friends
                </p>
                <div style={{padding:"10px"}}>
                    <table>
                        <tr>
                            <td>
                            <span style={{color:"white",fontWeight:"500",fontSize:"20px",borderRadius:"50%",backgroundColor:"rgb(225, 57, 120)",padding:"10px"}}>
                                UX
                            </span>
                            </td>
                            <td>
                                <div>
                                    <div>
                                        &nbsp;&nbsp;Michal
                                    </div>
                                    <div>
                                        &nbsp;&nbsp;Dublin, Dublin 8
                                    </div>
                                    <button onClick={hadleRequest} style={{backgroundColor: reqested ? "rgb(51, 101, 240)" : "white", marginTop: "5px", border: reqested ? "1px solid white" : "1px solid black", borderRadius: "20px", padding: "5px", display: "flex", color: reqested ? "white" : "#4a5568"}}>
                                        
                                        {reqested ? <>&nbsp;Requested</> :
                                            <>
                                                <svg style={{marginTop: "5px"}} stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 24 24" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12 4C11.4477 4 11 4.44772 11 5V11H5C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13H11V19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19V13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11H13V5C13 4.44772 12.5523 4 12 4Z" fill="currentColor"></path>
                                                </svg>
                                                
                                                Request
                                            </>
                                        }
                                        &nbsp;
                                    </button>

                                </div>
                            </td>
                        </tr>
                    </table>
                </div>

                <div style={{padding:"10px"}}>
                    <table>
                        <tr>
                            <td>
                            <span style={{color:"white",fontWeight:"500",fontSize:"20px",borderRadius:"50%",backgroundColor:"rgb(34 198 0)",padding:"10px"}}>
                                FD
                            </span>
                            </td>
                            <td>
                                <div>
                                    <div>
                                        &nbsp;&nbsp;Front-end Developer 
                                    </div>
                                    <div>
                                        &nbsp;&nbsp;Education Management
                                    </div>
                                    <button onClick={hadleRequest} style={{marginTop:"5px",border:"1px solid black",borderRadius:"20px",padding:"5px",display:"flex",color:"#4a5568"}}>
                                        &nbsp;
                                        <svg style={{marginTop:"5px"}} stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 24 24" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M12 4C11.4477 4 11 4.44772 11 5V11H5C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13H11V19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19V13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11H13V5C13 4.44772 12.5523 4 12 4Z" fill="currentColor"></path></svg>
                                        Request &nbsp;
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>


                <div style={{padding:"10px"}}>
                    <table>
                        <tr>
                            <td>
                            <span style={{color:"white",fontWeight:"500",fontSize:"20px",borderRadius:"50%",backgroundColor:"rgb(57 106 225)",padding:"10px"}}>
                                JS
                            </span>
                            </td>
                            <td>
                                <div>
                                    <div>
                                        &nbsp;&nbsp;Java Script Developer 
                                    </div>
                                    <div>
                                        &nbsp;&nbsp;Education Management
                                    </div>
                                    <button onClick={hadleRequest} style={{marginTop:"5px",border:"1px solid black",borderRadius:"20px",padding:"5px",display:"flex",color:"#4a5568"}}>
                                        &nbsp;
                                        <svg style={{marginTop:"5px"}} stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 24 24" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M12 4C11.4477 4 11 4.44772 11 5V11H5C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13H11V19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19V13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11H13V5C13 4.44772 12.5523 4 12 4Z" fill="currentColor"></path></svg>
                                        Request &nbsp;
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </Con>
    );
}

export default Right_bar;

const Con=styled.div`
@media(max-width:1000px)
{
    display:none;
}
`;