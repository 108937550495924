import React from "react";
import styled from "styled-components";
import close from "../Image/close.png";

import { useState } from "react";
import { UserAuth } from "../Firebase/authcontext";

import "../CSS/bootstrap-5.3.3-dist/bootstrap-5.3.3-dist/css/bootstrap.css";
import { storage } from "../Firebase/firebase";
import {ref,uploadBytes,getDownloadURL} from "firebase/storage";


function Post_Model(props) {
    const api_url="https://lindin-clone-30cfa-default-rtdb.asia-southeast1.firebasedatabase.app/UserData.json";
    const { logOut, user } = UserAuth();
    const [post_message,setMessage]=useState("")
    const [img_url,set_img_url]=useState("");
    const [image,setimage]=useState("");
    const [is_img,set_is_img]=useState(false);
    const [loading,setloading]=useState(true);
    const reset =(e)=>{
        props.handleClick(e);
    }
    let GenerateImageName=(email)=>{
        let date=new Date();
        return email+Date.now();
    }
    function handleImageChange(e){
        if(e.target.files[0]){
            setimage(e.target.files[0]);
            set_is_img(true);
        }
    }



    async function handleSubmit(e) {
        e.preventDefault();
    
        try {
            let url="";
            const user_name = user.displayName;
            const likes = 0;
            const followers = 0;
            const pfp = user.photoURL;
            if(is_img){
                const image_name=GenerateImageName(user.email);
                const imgRef = ref(storage, image_name);
                await uploadBytes(imgRef, image);
                url = await getDownloadURL(imgRef);
            }
            set_img_url(url);
            setimage("");
    
            const option = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    post_message,
                    user_name,
                    likes,
                    followers,
                    img_url: url, 
                    pfp:pfp
                })
            };
    
            const res = await fetch(api_url, option);
            if (res.ok) {
                reset(e);
            } else {
                alert("Error");
            }
        } catch (err) {
            alert("Something Went Wrong");
        }
    }
    

    return(
        <>
            {props.button_stat ==="open" &&
            <Container>
                <Content>
                    <Header>
                        <h2 style={{fontWeight:"600",paddingLeft:"10px"}}>Create a Post</h2>
                        <button onClick={(event)=>{reset(event)}} style={{backgroundColor:"#c8c8c8",border:"3px solid black"}}>
                            <img style={{width:"30px"}} src={close} alt=""/>
                        </button>
                    </Header>
                    <br/>
                    <ShareContent>
                        <UserInfo>
                            <img src={user?.photoURL} alt="" />
                            <p style={{paddingTop:"10px",paddingLeft:"10px",fontWeight:"600"}}>{user?.displayName}</p>
                        </UserInfo>
                    </ShareContent>
                    <form onSubmit={handleSubmit}>
                        <div style={{padding:"15px"}}>
                            <div className="mb-3">
                                <textarea required onChange={(e)=>{setMessage(e.target.value)}} value={post_message} placeholder="What do you want to talk about?" className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                            </div>
                        </div>
                        <ButtonContainer>
                            <ImageButton >
                                    {/* <center>
                                        <img src={imageIcon} alt="Image" />
                                    </center> */}
                                    <input onChange={handleImageChange} type="file" />
                            </ImageButton>
                            <button type="submit" className="btn btn-primary">Post</button>
                        </ButtonContainer>
                    </form>

                </Content>
            </Container>
            }
        </>
    );
}

export default Post_Model;

const ImageButton = styled.button`
    width: calc(50% - 10px);
    background-color:#ECECEC;
    border: none;
    border-radius:10px;
    padding: 0;
    cursor: pointer;
    img {
        width: 24px; /* Adjust the width of the image icon as needed */
        height: auto;
    }
`;

const Container=styled.div`
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:9999;
    color:black;
    background-color:rgb(0,0,0,0.8)
`;

const Content=styled.div`
    width:100%;
    max-width:552px;
    background-color:white;
    max-height:90%;
    overflow:initial;
    border-radius:5px;
    position:relative;
    display:flex;
    flex-direction:column;
    top:52px;
    margin:0 auto;
`;

const Header=styled.div`
    width:100%;
    display:block;
    padding:16px 5px;
    border-bottom:1px solid rgba(0,0,0,0.15);
    font-size:16px;
    color:rgba(0,0,0,0.6);
    line-height:1.5;
    display:flex;
    justify-content:space-between;
    align-items:center;
`;  

const ShareContent=styled.div`
    display:flex;
    flex-direction:column;
    overflow-y:auto;
    vertical-align:baseline;
    padding-top:-5px;
    padding:8px 12px;
`;

const UserInfo=styled.div`
    display:flex;
    align-items:center;
    padding:10px 10px;
    svg,img {
        width:48px;
        height:48px;
        background-clip:content-box;
        border:2px solid transparent;
        border-radius:50%;
    }
    span {
        font-weight:600;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    margin-bottom: 15px;
    button {
        width: calc(50% - 10px);
    }
`;