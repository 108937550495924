// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { getStorage } from "firebase/storage"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBpGR9r_XAFYgxRbAb7IoJLi5BttTjcxaE",
  authDomain: "lindin-clone-30cfa.firebaseapp.com",
  projectId: "lindin-clone-30cfa",
  storageBucket: "lindin-clone-30cfa.appspot.com",
  messagingSenderId: "556475707210",
  appId: "1:556475707210:web:dad9271860f45954d35bb3",
  measurementId: "G-89C7G9PRHF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth=getAuth(app);
const provider= new GoogleAuthProvider();
const imgDB = getStorage(app)

export default auth;
export const storage=getStorage(app);