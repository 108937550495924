import React from "react";
import Lindin from "../Image/linkedin.png";
import { Nav, NavItem, NavLink } from "reactstrap";
import styled from "styled-components";
import hero from "../Image/hero.svg";
import Signin from "./Signin_button";
import { Row, Col } from "react-bootstrap";
import "../CSS/signing.css";

function Sign() {
  return (
    <Container>
      <Nav>
        <NavItem>
          <NavLink>
            <h3 style={{color:"#2e9bd9"}}>SocioConnect</h3>
          </NavLink>
        </NavItem>
      </Nav>
      <div>
        <Row>
          <Col xs={12} md={6}>
            <p
              className="heading"
              style={{
                color: "rgb(143, 88, 73)",
                fontWeight: "300",
                textAlign: "left"
              }}
            >
              Welcome to your <br /> professional community
            </p>
            <div style={{ paddingTop: "35px",paddingBottom:"35px", textAlign: "center" }}>
              <Signin />
            </div>
          </Col>
          <Col xs={12} md={6}>
            <Man>
              <img
                src={hero}
                alt="Hero"
                style={{ width: "100%", height: "auto" }}
              />
            </Man>
          </Col>
        </Row>
      </div>
    </Container>
  );
}
export default Sign;

const Container = styled.div`
  padding: 50px;
  overflow-y: hidden;
  @media (max-width: 700px) {
    padding: 20px;
  }
`;

const Man = styled.div`
  img {
    max-width: 100%;
    height: auto;
  }
`;
