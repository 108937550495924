import React, { useEffect, useState } from "react";
import styled from "styled-components";
import close from "../Image/close.png";

import Comment from "./Comment";
import { UserAuth } from "../Firebase/authcontext";

function Comment_Model(props) {
    let [cmt, set_smt] = useState("");
    let [cmtData, set_cmtData] = useState({});
    let { logout, user } = UserAuth();
    const url = "https://lindin-clone-30cfa-default-rtdb.asia-southeast1.firebasedatabase.app/UserComments.json";

    async function getComments() {
        let data = await fetch(url);
        data = await data.json();
        set_cmtData(data);
    }

    async function handleSubmit(e) {
        e.preventDefault();
        try {
            const user_name = user.displayName;
            const pfp = user.photoURL;
            const Key = props.id;
            const option = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    id: Key,
                    comment: cmt,
                    name: user_name,
                    likes: 0,
                    pfp: pfp
                })
            };
            const res = await fetch(url, option);
            if (res.ok) {
                set_smt("");
                getComments();
            } else {
                alert("Error");
            }
        } catch (err) {
            console.log(err);
            set_smt("");
            alert("Error occurred while uploading the image.");
        }
    }

    useEffect(() => {
        getComments();
    }, [url]);

    return (
        <>
            {
                props.cmt_stat === "open" &&
                <Container>
                    <Content>
                        <div className="row" style={{ padding: "15px" }}>
                            <div className="col text-start">
                                <h3>Comments</h3>
                            </div>
                            <div className="col ">
                                <button style={{ float: "right", backgroundColor: "#c8c8c8", border: "1px solid black" }} onClick={props.onClick}><img style={{ width: "40px" }} src={close} alt="" /></button>
                            </div>
                            <div className="row align-items-center">
                                <div className="col-auto pr-0">
                                    <img style={{ borderRadius: "50%", width: "40px" }} src="https://lh3.googleusercontent.com/a/ACg8ocJEduhpvPUsppeA0lEdP8lrMrSsxWDd9jkUjeP6XpFXLGGT=s96-c" alt="Errr" />
                                </div>
                                <div className="col pl-0 ml-2">
                                    <span style={{ float: "left", fontWeight: "500", display: "block" }}>Mohd Zakie Khan</span>
                                </div>
                            </div>

                            <div className="comments-container" style={{ maxHeight: "250px", overflowY: "auto" }}>
                                {
                                    Object.keys(cmtData).reverse().filter((j) => cmtData[j].id == props.id).map((i) => {
                                        return <Comment key={i} name={cmtData[i].name} pfp={cmtData[i].pfp} comment={cmtData[i].comment} likes={cmtData[i].likes} />;
                                    })
                                }
                            </div>
                            <form onSubmit={handleSubmit} className="mt-3">
                                <input required className="form form-control" type="text" onChange={(e) => { set_smt(e.target.value) }} value={cmt} placeholder="Write Comment..." />
                                <div className="d-grid gap-3 mt-3 mb-0">
                                    <button className="btn btn-primary" type="submit">
                                        Comment
                                    </button>
                                </div>
                            </form>
                        </div>
                    </Content>
                </Container>
            }
        </>
    );
}
export default Comment_Model;

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    color: black;
    background-color: rgba(0, 0, 0, 0.8);
`;

const Content = styled.div`
    width: 100%;
    max-width: 552px;
    background-color: white;
    max-height: 90%;
    overflow: initial;
    border-radius: 5px;
    position: relative;
    display: flex;
    flex-direction: column;
    top: 52px;
    margin: 0 auto;
`;
